import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import PageLink from '../global/PageLink'
import slugify from 'slugify'

import queryAirtableCategories from '../../staticQueries/queryAirtableCategories'

const CategoryCardsComponent = ({ className }) => {
  return (
    <ul tw="list-none flex flex-wrap -mx-2 md:-mx-3 pt-8" className={className}>
      {queryAirtableCategories().map(category => (
        <li tw="w-1/2 sm:w-1/3 pb-0 sm:pb-2 md:pb-3 px-1 sm:px-3" key={category.id}>
          <PageLink tw="block rounded-lg bg-gray-light p-2 xs:p-4 lg:p-8 text-sm h-full relative flex justify-center items-center"
            to={`/parts/${slugify(category.name, { lower: true })}`}>
            <div tw="w-full pt-full relative mb-12">
              <img src={category.image != null ? category.image.thumbnail : '/images/part-placeholder.jpg'} tw="object-contain absolute w-full h-full top-0 left-0" />
            </div>
            <span tw="absolute w-full bottom-0 left-0 px-3 pb-4 xs:pb-5 sm:pb-8 text-center">
              {category.name}
            </span>
          </PageLink>
        </li>
      ))}
    </ul>
  )
}

CategoryCardsComponent.propTypes = {
  className: PropTypes.string.isRequired,
  full: PropTypes.bool,
}

const CategoryCards = styled(CategoryCardsComponent)`
  ${tw``}
`
export default CategoryCards
