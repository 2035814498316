import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'

import queryAirtableParts from '../staticQueries/queryAirtableParts'

import Layout from '../components/Layout'
import Container, { Col } from '../components/global/Container'
import PartCategories from '../components/parts/PartCategories'
import PartsTable from '../components/parts/PartsTable'
import PageHeading from '../components/global/PageHeading'
import CategoryCards from '../components/global/CategoryCards'

export const PartsTemplate = ({ title, helmet }) => {

  const tableData = queryAirtableParts()

  return (
    <>
      {helmet || ''}
      <PageHeading to="/parts" title={title} />
      <Container>
        <PartCategories />
        <Col tw="flex-1 pt-10 overflow-auto">
          <div tw="lg:pl-4">
            <PartsTable placeholder={<CategoryCards />} data={tableData} />
          </div>
        </Col>
      </Container>
    </>
  )
}

PartsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  helmet: PropTypes.object,
}

const PartsPageComponent = ({ data }) => {
  const {
    markdownRemark: { frontmatter: page },
  } = data
  const { title, description } = page

  return (
    <Layout>
      <PartsTemplate
        title={title}
        helmet={
          <Helmet titleTemplate="%s - Techna NDT">
            <title>{`${title}`}</title>
            <meta name="description" content={`${description}`} />
          </Helmet>
        }
      />
    </Layout>
  )
}

PartsPageComponent.propTypes = {
  data: PropTypes.object.isRequired,
}

const PartsPage = styled(PartsPageComponent)`
  ${tw``}
`

export default PartsPage

export const pageQuery = graphql`
  query PartsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
